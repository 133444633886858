import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { bsc } from 'viem/chains';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faPeopleCarry, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Web3 from 'web3';
import { Transaction as EthereumTx } from 'ethereumjs-tx';
import { fetchAbi } from './abi';



const CopyButton = styled.button`
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #f39c12;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e08e0b;
    }
`;


const spinAnimation = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #f39c12;
    border-radius: 50%;
    animation: ${spinAnimation} 0.6s linear infinite;
`;



const ActivateButton = styled.button`
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #f39c12;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e08e0b;
    }
`;

const UserIdText = styled.p`
    color: #ecf0f1;
    font-size: 1.2em;
    margin-top: 10px;
`;

const ContentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    padding-bottom: 60px;
`;

const BottomNavContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #262626;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    z-index: 2;
`;

const NavItem = styled.div`
    text-align: center;
    cursor: pointer;

`;

const Icon = styled.div`
    width: 30px;
    height: 30px;
    background-color: #f39c12;
    border-radius: 50%;
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #1a1a1a;
    overflow: hidden;
`;

const Card = styled.div`
    background-color: #262626;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
`;

const Title = styled.h2`
    color: #ecf0f1;
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: center;
`;

const Popup = styled.div`
color: #ecf0f1;
font-size: 1.2em;
margin-bottom: 20px;
text-align: center;
`;
const Countdown = styled.p`
color: #f39c12;
font-size: 1.8em;
margin-bottom: 20px;
text-align: center;
`;

const BalanceCard = styled.div`
  background-color: #262626;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const BottomNavigation = () => {
    return (
        <BottomNavContainer>
            <NavItem>
                <a href="https://zemcos.com/" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faHome} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
                </a>
            </NavItem>
            <NavItem>
                <a href="/matrix" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faPeopleCarry} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
                </a>
            </NavItem>
            <NavItem>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
            </NavItem>
        </BottomNavContainer>

    );
};



const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [endDate, setEndDate] = useState(new Date('2024-02-01T00:00:00'));
    const [activationInProgress, setActivationInProgress] = useState(false);
    const [parsedPackageId, setParsedPackageId] = useState<string | null>(null);
    const [balance, setBalance] = useState(null);
    const [recipientAddresses, setRecipientAddresses] = useState<string[]>([]);
    const [paymentNumber, setPaymentNumber] = useState(null);




    useEffect(() => {
        const fetchData = async () => {
            try {
                // Simulate a delay for 3 seconds
                await new Promise(resolve => setTimeout(resolve, 3000));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);







    const projectId = 'ebbfecd20f47ecb2fac81f5979ba2d48';

    const metadata = {
        name: 'zemcos',
        description: 'Web3Modal Example',
        url: 'https://web3modal.com',
        icons: ['https://avatars.githubusercontent.com/u/37784886'],
    };

    const chains = [bsc];
    const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
    createWeb3Modal({ wagmiConfig, projectId, chains });

    useEffect(() => {
        const checkWallet = async () => {
            try {
                if (window.ethereum) {
                    const ethereum = window.ethereum as any;
                    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                    const walletAddress = accounts[0];

                    // Verify the wallet address
                    const response = await axios.get(`https://zemcos.zemcos.com/api/verify-wallet?walletAddress=${walletAddress}`);
                    const verificationResult = response.data;

                    if (verificationResult.isValid) {
                        setWalletAddress(walletAddress);
                        setUserId(verificationResult.user_id);
                        console.log('Wallet connected successfully! Wallet Address:', walletAddress);
                    } else {
                        console.error('Wallet address verification failed.');
                        navigate('/');
                    }
                }
            } catch (error) {
                console.error('Error checking wallet address:', error);
            }
        };

        checkWallet().catch(error => {
            console.error('Unexpected error during wallet check:', error);
        });
    }, [navigate]);

    const handleCopyId = () => {
        if (userId) {
            // Create a temporary textarea to copy the user ID to the clipboard
            const textarea = document.createElement('textarea');
            textarea.value = `https://app.zemcos.com/walc/${userId}`;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            // Set the copied state to true and reset it after a delay
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        }
    };

    const copyLink = `https://app.zemcos.com/walc/1/${userId}`;








    const usdtContractAbi = [
        {
            "constant": false,
            "inputs": [
                {
                    "name": "_to",
                    "type": "address"
                },
                {
                    "name": "_value",
                    "type": "uint256"
                }
            ],
            "name": "transfer",
            "outputs": [
                {
                    "name": "",
                    "type": "bool"
                }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ];




























    useEffect(() => {
        const checkActivationStatus = async () => {
            try {
                if (walletAddress) {
                    const response = await axios.get(`https://zemcos.zemcos.com/api/activate-check?walletAddress=${walletAddress}`);
                    const activationStatus = response.data;

                    if (activationStatus.isActivated === 1) {
                        setActivationInProgress(false);
                        console.log('User is activated.');
                    } else {
                        setActivationInProgress(true); // Set to true when not activated
                        console.log('User is not activated.');
                    }
                }
            } catch (error) {
                console.error('Error checking activation status:', error);
            }
        };

        checkActivationStatus().catch(error => {
            console.error('Unexpected error during activation status check:', error);
        });
    }, [walletAddress]);









    useEffect(() => {
        const sendWalletAddress = async () => {
            try {
                if (walletAddress) {
                    const response = await axios.get(`https://zemcos.zemcos.com/api/packge_id/${walletAddress}`);
                    const responseData = response.data;

                    // Set the parsed package ID state
                    setParsedPackageId(responseData.parsed_package_id);
                }
            } catch (error) {
                console.error('Error sending wallet address:', error);
            }
        };

        sendWalletAddress().catch(error => {
            console.error('Unexpected error while sending wallet address:', error);
        });
    }, [walletAddress]);





    useEffect(() => {
        const delayLoading = setTimeout(() => {
            setLoading(false);
        }, 5000);

        return () => clearTimeout(delayLoading);
    }, []);



        useEffect(() => {
            const fetchBalance = async () => {
                try {
                    if (walletAddress) {
                        const response = await axios.get(`https://zemcos.zemcos.com/api/balance/${walletAddress}`);
                        const balanceData = response.data.balance;
                        setBalance(balanceData);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setLoading(false);
                }
            };

            fetchBalance().catch(error => {
                console.error('Unexpected error while fetching balance:', error);
                setLoading(false);
            });
        }, [walletAddress]);



    useEffect(() => {
        const fetchUplineData = async () => {
            try {
                if (walletAddress) {
                    const response = await axios.get(`https://zemcos.zemcos.com/api/upline/${walletAddress}`);
                    const uplineData = response.data;
                    console.log('Upline Data:', uplineData);
                    // Update state or perform other actions with the upline data
                }
            } catch (error) {
                console.error('Error fetching upline data:', error);
            }
        };

        fetchUplineData().catch(error => {
            console.error('Unexpected error while fetching upline data:', error);
        });
    }, [walletAddress]);







    useEffect(() => {
        const fetchUplineData = async () => {
            try {
                const responseFirst = await axios.get(`https://zemcos.zemcos.com/api/upline/${walletAddress}`);
                console.log('First Upline Data:', responseFirst.data);

                const responseSecond = await axios.get(`https://zemcos.zemcos.com/api/upline/${walletAddress}/second`);
                console.log('Second Upline Data:', responseSecond.data);

                const responseThird = await axios.get(`https://zemcos.zemcos.com/api/upline/${walletAddress}/third`);
                console.log('Third Upline Data:', responseThird.data);

                const responseFourth = await axios.get(`https://zemcos.zemcos.com/api/upline/${walletAddress}/fourth`);
                console.log('Fourth Upline Data:', responseFourth.data);

                const responseFifth = await axios.get(`https://zemcos.zemcos.com/api/api/upline/fifth-wallet-address`);
                console.log('Fifth Upline Data:', responseFifth.data);

                return [
                    responseFifth.data.wallet_address,
                    responseFirst.data.wallet_address,
                    responseSecond.data.wallet_address,
                    responseThird.data.wallet_address,
                    responseFourth.data.wallet_address,

                ];
            } catch (error) {
                console.error('Error fetching upline data:', error);
                return []; // Return empty array or handle error appropriately
            }
        };

        const fetchData = async () => {
            try {
                if (walletAddress) {
                    const response = await axios.get(`https://zemcos.zemcos.com/api/activate-check?walletAddress=${walletAddress}`);
                    const activationStatus = response.data;

                    if (activationStatus.isActivated === 1) {
                        console.log('User is already activated. Skipping activation process.');
                        return; // Skip further activation steps
                    }

                    const recipientAddresses = await fetchUplineData();
                    if (recipientAddresses.length !== 5) {
                        console.error('Not all recipient addresses were fetched.');
                        return;
                    }

                    console.log('Parsed Package ID:', parsedPackageId); // Log the parsedPackageId value for debugging

                    if (parsedPackageId === '1') {
                        handleActivation(recipientAddresses);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Delay the execution of fetchData by 3 seconds
        const delayFetchData = setTimeout(() => {
            fetchData();
        }, 3000);

        return () => clearTimeout(delayFetchData); // Clear the timeout if the component unmounts or if the effect re-runs

    }, [walletAddress, parsedPackageId]); // Add walletAddress and parsedPackageId to the dependency array






    const handleActivation = async (recipientAddresses: [string, string, string, string] | any[]) => {
        console.log('handleActivation function called');

        try {
            if (!walletAddress) {
                console.error('No wallet connected.');
                setActivationInProgress(true);
                return;
            }

            if (!window.ethereum) {
                console.error('Ethereum provider not found.');
                return;
            }

            const web3 = new Web3(window.ethereum);

            if (!web3) {
                console.error('Web3 not initialized.');
                return;
            }

            const utils = web3.utils as any;

            const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';

            // Fetch the payment number
            const response = await axios.get(`https://zemcos.zemcos.com/api/check-payment-number?walletAddress=${walletAddress}`);
            const { success, paymentNumber } = response.data;
            console.log('Payment Number Data:', success, paymentNumber);

            // Determine the start payment index based on the fetched payment number
            let startPaymentIndex = paymentNumber ? paymentNumber + 1 : 1; // If paymentNumber is null or 1, start from 2

// Loop through recipientAddresses starting from startPaymentIndex
            for (let i = startPaymentIndex - 1; i < recipientAddresses.length; i++) {
                const recipientAddress = recipientAddresses[i];
                let amountToSend;

                switch (i + 1)  {
                    case 1:
                        amountToSend = utils ? utils.toWei('35.00', 'ether') : '35000000000000000000'; // 35.00 USDT in wei

                        break;
                    case 2:
                        amountToSend = utils ? utils.toWei('12.50', 'ether') : '12500000000000000000'; // 12.50 USDT in wei
                        break;

                    case 3:
                        amountToSend = utils ? utils.toWei('6.25', 'ether') : '6250000000000000000'; // 6.25 USDT in wei
                        break;

                    case 4:
                        amountToSend = utils ? utils.toWei('3.1255', 'ether') : '3125500000000000000'; // 3.1255 USDT in wei
                        break;

                    case 5:
                        amountToSend = utils ? utils.toWei('3.1255', 'ether') : '3125500000000000000'; // 3.1255 USDT in wei
                        break;



                }

                const gasEstimate = await web3.eth.estimateGas({
                    to: usdtContractAddress,
                    from: walletAddress,
                    data: utils ? utils.sha3("transfer(address,uint256)").substr(0, 10) +
                        web3.eth.abi.encodeParameters(['address', 'uint256'], [recipientAddress, amountToSend]).substr(2) : ''
                });

                const transaction = await web3.eth.sendTransaction({
                    to: usdtContractAddress,
                    from: walletAddress,
                    data: utils ? utils.sha3("transfer(address,uint256)").substr(0, 10) +
                        web3.eth.abi.encodeParameters(['address', 'uint256'], [recipientAddress, amountToSend]).substr(2) : '',
                    gas: gasEstimate,
                });

                console.log(`USDT Transaction Hash (Address ${i}):`, transaction.transactionHash);

                // Send payment details to the API
                await axios.post('https://zemcos.zemcos.com/api/payments', {
                    transactionHash: transaction.transactionHash,
                    recipientAddress,
                    amount: amountToSend,
                    walletAddress: walletAddress,
                    paymentNumber: i + 1// Payment number starts from 1
                });

                console.log('Payment request sent successfully.');



                // Break the loop after processing the last payment

                    // Send completion notification after all payments are processed
                    await axios.post('https://zemcos.zemcos.com/api/paymentscomplete', {
                        walletAddress: walletAddress
                    });

                    console.log('All payments completed successfully.');
                window.location.reload();

            }

        } catch (error) {
            console.error('Error during activation:', error);
        }
    };



    useEffect(() => {
        const fetchPaymentNumber = async () => {
            try {
                if (walletAddress) {
                    const response = await axios.get(`https://zemcos.zemcos.com/api/check-payment-number?walletAddress=${walletAddress}`);
                    const { success, paymentNumber } = response.data;
                    console.log('Payment Number Data:', success, paymentNumber);
                    if (success) {
                        setPaymentNumber(paymentNumber);
                        console.log('Payment number fetched successfully.');
                    } else {
                        console.error('Failed to fetch payment number.');
                    }
                } else {
                    console.error('Wallet address not available.');
                }
            } catch (error) {
                console.error('Error fetching payment number:', error);
            }
        };

        // Fetch payment number when walletAddress changes
        if (walletAddress) {
            fetchPaymentNumber();
        }
    }, [walletAddress]); // Dependency on walletAddress





    const handleActivationtwo = async () => {
        try {
            // Check if a wallet is connected
            if (!walletAddress) {
                console.error('No wallet connected.');
                return;
            }

            // Initialize web3 with the current provider (WalletConnect)
            const web3 = new Web3(window.ethereum);

            // Create a contract instance using the fetched ABI
            const usdtContract = new web3.eth.Contract(usdtContractAbi, '0x55d398326f99059fF775485246999027B3197955'); // USDT contract address on BSC

            // The amount to send in Wei (USDT uses 6 decimal places)
            const totalAmountToSend = web3.utils.toWei('510', 'ether');  // 5 USDT in Wei

            // The recipients (your wallet addresses)
            const recipientAddress1 = '0xd2cAA3E9579433C8Ca4af4DE9E3CE5f3ADfb753D';// Replace with the second address

            // Estimate the gas needed for the transaction
            const gasEstimate = await usdtContract.methods.transfer(recipientAddress1, totalAmountToSend).estimateGas({ from: walletAddress });

            // Convert gasEstimate to string
            const gasLimit = gasEstimate.toString();

            // You can use web3.eth.getGasPrice() to fetch the current gas price from the network
            const gasPriceBigint = await web3.eth.getGasPrice();

            // Convert gasPrice to string
            const gasPrice = gasPriceBigint.toString();

            // Send to the first recipient
            const transaction1 = await usdtContract.methods.transfer(recipientAddress1, totalAmountToSend)
                .send({ from: walletAddress, gas: gasLimit, gasPrice });

            // Handle the transaction responses...
            console.log('USDT Transaction Hash (Address 1):', transaction1.transactionHash);

            // After completing the transaction, send data to your backend API
            await axios.post('https://zemcos.zemcos.com/api/payments', {
                transactionHash: transaction1.transactionHash,
                recipientAddress: recipientAddress1,
                amount: totalAmountToSend,
                walletAddress: walletAddress,
                paymentNumber: 1 // Payment number starts from 1
            });
            await axios.post('https://zemcos.zemcos.com/api/paymentsdone', {

                walletAddress: walletAddress,
            });
            window.location.reload();
        } catch (error) {
            console.error('Error during activation:', error);
        }
    };



    interface CenteredTextProps {
        children: React.ReactNode;
        style?: React.CSSProperties;
    }

    const CenteredText: React.FC<CenteredTextProps> = ({ children, style }) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>
            {children}
        </div>
    );

    return (
        <PageContainer>
            <ContentContainer>


                        <Card>
                            <Title>Welcome to the Dashboard!</Title>
                            {userId && <UserIdText>Your User ID: {userId}</UserIdText>}
                            <CopyButton onClick={handleCopyId}>Copy Your Refer Link</CopyButton>
                            {copied && <Popup>Copied!</Popup>}
                        </Card>

                        {/* Conditional rendering based on activation status */}
                {activationInProgress ? (
                    <BalanceCard style={{ color: '#fff' }}>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <>
                                {parsedPackageId === '1' && (
                                    // Inside your Dashboard component

                                        <ActivateButton>
                                            Activate with 60 USDT
                                        </ActivateButton>

                                )}

                                {parsedPackageId === '2' && (
                                    <ActivateButton onClick={handleActivationtwo}>Activate with 510 USDT</ActivateButton>
                                )}
                            </>
                        )}
                    </BalanceCard>
                ) : (

                    <BalanceCard>
                        {balance !== null ? (
                            <>
                                <h2 style={{ color: '#fff' }}>Equity Balance</h2>
                                <p style={{ color: '#e08e0b', fontSize: '20px' }}>{balance}</p>
                            </>
                        ) : (
                            <p style={{ color: '#e08e0b' }}>Loading balance...</p>
                        )}
                    </BalanceCard>

                )}


                {/* Additional content goes here */}
                        <>
                            {/* Add your additional content here */}
                        </>


            </ContentContainer>

            <BottomNavigation />
        </PageContainer>
    );
};

export default Dashboard;