// src/StepsPage.tsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import { bsc } from 'viem/chains';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


interface Props {
  packageData: any; // Change 'any' to the actual type of packageData
}
const LogoContainer = styled.div`
width: 120px;
`;

const LogoImage = styled.img`
width: 120px;
border-radius: 50%;
margin-bottom: 20px;
`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios Error:', error);
    return Promise.reject(error);
  }
);
const sendDataToLaravel = async (data: any) => {
  try {
    // Wrap the code inside a DOMContentLoaded event listener
    document.addEventListener("DOMContentLoaded", async () => {
      const csrfTokenMetaTag = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]');
      const host = window.location.host;

      if (csrfTokenMetaTag) {
        const csrfToken = csrfTokenMetaTag.content;

        const response = await axios.post('https://zemcos.zemcos.com/api/register', data, {
          headers: {
            'XSRF-TOKEN': csrfToken,
            'Host': host,
          },
        });

        if (response.data.success) {
          console.log('Data sent to Laravel successfully.');
        } else {
          console.error('Failed to send data to Laravel.');
        }
      } else {
        console.error('CSRF meta tag not found.');
      }
    });
  } catch (error) {
    console.error('Error sending data to Laravel:', error);
  }
};




const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a1a; /* Dark background color */
`;



// Updated keyframes declaration
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const lineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;
const StepsContainer = styled.div`
  background-color: #262626;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
  position: relative;
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const StepPoint = styled.div<{ active: boolean; completed: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ active, completed }) =>
    active ? '#f1c40f' : completed ? '#2ecc71' : '#ccc'};
  margin: 0 10px;
`;

const StepLine = styled.div<{ active: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ active }) => (active ? '#f1c40f' : '#ccc')};
  transform: translateY(-1px); /* Adjust to center the line */
  z-index: -1; /* Behind the step points */
`;

const StepLabel = styled.div<{ active: boolean }>`
  margin: 0 10px;
  color: ${({ active }) => (active ? '#f1c40f' : '#ccc')};
`;






const StepCard = styled.div`
  background-color: #262626; /* Darker card background color */
  padding: 30px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const FormLabel = styled.label`
  color: #ecf0f1; /* Light text color */
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const FormInput = styled.input`
  padding: 15px;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #262626; /* Darker card background color */
  color: #ecf0f1; /* Light text color */
`;












// Styled components
const Web3ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: #ecf0f1;
  font-size: 1.8em;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1em;
  margin-bottom: 20px;
`;



const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Set the direction to column */
  align-items: center; /* Center items horizontally */
  gap: 10px;
  margin-top: 20px; /* Add margin for separation from the FormInput in the second ButtonContainer */
`;


const Button = styled.button`
  background-color: #f39c12;
  color: #fff;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.2em;
  width: 100%;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #e67e22;
  }
`;


const StepsPage: React.FC<Props> = ({ packageData }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [registrationStatus, setRegistrationStatus] = useState<'success' | 'error' | null>(null);
  const [loading, setLoading] = useState(false);
  const [referCode, setReferCode] = useState('');
  const { packageId, referCode: referCodeFromUrl } = useParams();
  const parsedPackageId = packageId;
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null); // Add this line to declare 'setError'


  useEffect(() => {
    // Set the refer code from the URL parameter
    if (referCodeFromUrl) {
      setReferCode(referCodeFromUrl);
    }
  }, [referCodeFromUrl]);





  const handleConnectWallet = async () => {
    try {
      setLoading(true);

      if (window.ethereum) {
        const ethereum = window.ethereum as any;
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];

        // Check if the wallet address already exists in the database
        const isWalletAlreadyRegistered = await checkIfWalletExists(walletAddress);

        if (isWalletAlreadyRegistered) {
          console.log('User already has an account.');


          // Navigate to the dashboard page with the user ID
          navigate(`/dashboard`);
        } else {
          console.log('Wallet connected successfully! Wallet Address:', walletAddress, parsedPackageId);

          // Check if parsedPackageId is present
          if (parsedPackageId === '1' || parsedPackageId === '2') {
            // If parsedPackageId is found, proceed to step 2
            setWalletAddress(walletAddress);
            setCurrentStep(2);
          } else {
            // If parsedPackageId is not found, navigate to the package page

            navigate(`/${parsedPackageId}`);

          }
        }
      } else {
        console.error('No Ethereum provider found');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    } finally {
      setLoading(false);
    }
  };


  const checkIfWalletExists = async (walletAddress: string) => {
    try {
      const response = await axios.get(`https://zemcos.zemcos.com/api/check-wallet?walletAddress=${walletAddress}`);
      return response.data.exists;
    } catch (error: any) { // Specify 'any' as the type for the error variable
      console.error('Error checking if wallet exists:', error.response?.data ?? error.message);
      return false;
    }
  };


  // Define the ErrorText component outside the function
  const ErrorText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div style={{ color: 'red', marginTop: '8px' }}>{children}</div>
  );


  const verifyReferCode = async (code: string) => {
    try {
      const response = await axios.get(`https://zemcos.zemcos.com/api/verify-refer-code?referCode=${code}`);
      return response.data.isValid;
    } catch (error) {
      console.error('Error verifying refer code:', error);
      return false;
    }
  };

  const handleReferCode = async (e: React.FormEvent) => {
    e.preventDefault();

    console.log('Refer Code:', referCode);

    const isValidReferCode = await verifyReferCode(referCode);

    console.log('Is Valid Refer Code:', isValidReferCode);

    if (isValidReferCode) {
      setCurrentStep((prevStep) => prevStep + 1);
      setError(null);
    } else {
      setError('Invalid refer code. Please try again.');
    }
  };


  const handleSimpleButton = async () => {
    try {
        setLoading(true);
        console.log(   packageData,
          referCode,
          walletAddress,
          parsedPackageId,);
        // Send data to Laravel
        const response = await axios.post('https://zemcos.zemcos.com/api/register', {
            packageData,
            referCode,
            walletAddress,
            parsedPackageId,
            // Add other user registration data...
        });
        axios.interceptors.response.use(
          response => response,

          error => {
            console.error('Axios Error:', error);
            return Promise.reject(error);
          }
        );


        console.log('Server Response:', response); // Log the full response

        if (response.data.message) {
            console.log('User registered successfully:', response.data.user);
            setRegistrationStatus('success');
        } else {
            console.error( "message");
            setRegistrationStatus('error');
        }

     } catch (error) {
        console.error('Error during registration:', error);
        setRegistrationStatus('error');
    } finally {
        setLoading(false);
    }
};



  const handleCancel = () => {
    console.log('Cancel clicked!');
    setCurrentStep(1);
  };

  const projectId = 'ebbfecd20f47ecb2fac81f5979ba2d48';

  const metadata = {
    name: 'zemcos',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
  };

  const chains = [bsc];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
  createWeb3Modal({ wagmiConfig, projectId, chains });

  const shouldRenderStepIndicator = parsedPackageId === '1' || parsedPackageId === '2';

  return (
    <PageContainer>
      <StepsContainer>

<LogoContainer>
        <LogoImage src="https://test.zemcos.com/wp-content/uploads/2023/08/favicon.png" alt="Logo" />
      </LogoContainer>
      {shouldRenderStepIndicator && (
       <StepIndicator>
       <StepLine active={currentStep > 1} />
       <StepPoint active={currentStep === 1} completed={currentStep > 1} />
       <StepLabel active={currentStep === 1}>Step 1</StepLabel>

       <StepLine active={currentStep > 2} />
       <StepPoint active={currentStep === 2} completed={currentStep > 2} />
<StepLabel active={currentStep === 2}>Step 2</StepLabel>

       <StepLine active={currentStep > 3} />
       <StepPoint active={currentStep === 3} completed={currentStep > 3} />
       <StepLabel active={currentStep === 3}>Step 3</StepLabel>
     </StepIndicator>


        )}
        <StepCard>

          {currentStep === 1 && (
            <>
              <Title>Step 1: Connect Wallet</Title>
              <Description>Connect your wallet to get started.</Description>
              <ButtonContainer>
  <Web3ButtonContainer>
    <WagmiConfig config={wagmiConfig}>
      <w3m-button />
    </WagmiConfig>
  </Web3ButtonContainer>
  <Button onClick={handleConnectWallet}>Next</Button>
</ButtonContainer>
            </>
          )}


{currentStep === 2 && (
  <>
    <Title>Step 2: Refer Code</Title>
    <Description>Enter a referral code if you have one.</Description>
    <FormContainer onSubmit={handleReferCode}>
      <FormLabel htmlFor="referCode">Refer Code:</FormLabel>
      <FormInput
        type="text"
        id="referCode"
        value={referCode}
        onChange={(e) => setReferCode(e.target.value)}
        placeholder="Enter refer code"
        required
      />
      <Button type="submit">Next</Button>
    </FormContainer>
    {error && <ErrorText>{error}</ErrorText>}
  </>
)}

          {currentStep === 3 && (
            <>
              <Title>Step 3: Complete</Title>
              <Description>Click the login button to enter your Dashboard.</Description>
              <ButtonContainer>
                <Button onClick={handleSimpleButton} disabled={loading}>
                  {loading ? 'Processing...' : 'Log In'}
                </Button>
              </ButtonContainer>
              {registrationStatus === 'success' &&     navigate(`/dashboard`)}
              {registrationStatus === 'error' && <div>Error! Registration failed.</div>}
            </>
          )}
        </StepCard>
      </StepsContainer>
    </PageContainer>
  );
};

export default StepsPage;
