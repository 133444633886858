// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PackagePage from './PackagePage';
import StepsPage from './StepsPage';
import Dashboard from './Dashboard';
import Matrix from './Matrix';
import ExampleApiRequest from './components/ExampleApiRequest';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PackagePage />} />
        <Route path="/:referCode" element={<PackagePage />} />

        <Route path="/walc" element={<StepsPage packageData={'1'} />} />
        <Route path="/walc/:packageId" element={<StepsPage packageData={'1'} />} />
        <Route path="/walc/:packageId/:referCode" element={<StepsPage packageData={'1'} />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/matrix" element={<Matrix />} />

        <Route path="/test/:packageId" element={<ExampleApiRequest packageData={undefined} />} />

      </Routes>
    </Router>
  );
};

export default App;
