// src/PackagePage.tsx
import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

// Styled components
const ProfessionalBox = styled.div`
  background-color: #1a1a1a; /* Dark background color */
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const Logo = styled.img`
  width: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background-color: #262626; /* Darker card background color */
  padding: 30px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const Price = styled.p`
  color: #f1c40f;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  color: #ecf0f1; /* Light text color */
  font-size: 1.8em;
`;

const YellowButton = styled.button`
  background-color: #f39c12;
  color: #fff;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 1.2em;
  font-family: 'Arial', sans-serif;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #e67e22;
  }
`;

const PackagePage: React.FC = () => {
  const navigate = useNavigate();
  const { referCode } = useParams();

  const handleClick = async (packageId: number) => {
    navigate(`/walc/${packageId}/${referCode}`);
  };

  return (
    <ProfessionalBox>
      <Logo src="https://test.zemcos.com/wp-content/uploads/2023/08/logo.png" alt="Logo" />
      <Card>
        <Price>60 USDT</Price>
        <Title>BRICKS</Title>
        <YellowButton onClick={() => handleClick(1)}>Continue with $60</YellowButton>
      </Card>
      <Card>
        <Price>510 USDT</Price>
        <Title>BUILDER</Title>
        <YellowButton onClick={() => handleClick(2)}>Continue with $510</YellowButton>
      </Card>
    </ProfessionalBox>
  );
};

export default PackagePage;
