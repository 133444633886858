// src/StepsPage.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import { bsc } from 'viem/chains';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
interface Props {
  packageData: any; // Change 'any' to the actual type of packageData
}

// Styled components
const ProfessionalBox = styled.div`
  background-color: #1a1a1a;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const Card = styled.div`
  background-color: #262626;
  padding: 30px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const Title = styled.h2`
  color: #ecf0f1;
  font-size: 1.8em;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1em;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #f39c12;
  color: #fff;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 1.2em;
  font-family: 'Arial', sans-serif;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #e67e22;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormLabel = styled.label`
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
`;

const FormInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
`;
const ExampleApiRequest: React.FC<Props> = ({ packageData }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [registrationStatus, setRegistrationStatus] = useState<'success' | 'error' | null>(null);
  const [loading, setLoading] = useState(false);
  const [referCode, setReferCode] = useState('');
  const { packageId, referCode: referCodeFromUrl } = useParams();
  const parsedPackageId = packageId;
  const [walletAddress, setWalletAddress] = useState<string | null>(null);

  useEffect(() => {
    if (referCodeFromUrl) {
      setReferCode(referCodeFromUrl);
    }
  }, [referCodeFromUrl]);

  const handleConnectWallet = async () => {
    try {
      setLoading(true);

      if (window.ethereum) {
        const ethereum = window.ethereum as any;
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];

        const isWalletAlreadyRegistered = await checkIfWalletExists(walletAddress);

        if (isWalletAlreadyRegistered) {
          navigate(`/dashboard`);
        } else {
          if (parsedPackageId === '1' || parsedPackageId === '2') {
            setWalletAddress(walletAddress);
            setCurrentStep(2);
          } else {
            navigate(`/${parsedPackageId}`);
          }
        }
      } else {
        console.error('No Ethereum provider found');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkIfWalletExists = async (walletAddress: string) => {
    try {
      const response = await axios.get(`http://192.168.0.107/zemcos/public/api/check-wallet?walletAddress=${walletAddress}`);
      return response.data.exists;
    } catch (error: any) {
      console.error('Error checking if wallet exists:', error.response?.data ?? error.message);
      return false;
    }
  };



  const handleSimpleButton = async () => {
    try {
      setLoading(true);
      const response = await axios.post('http://192.168.0.107/zemcos/public/api/register', {
        packageData,
        referCode,
        walletAddress,
        parsedPackageId,
      });

      console.log('Server Response:', response);

      if (response.data.success) {
        console.log('User registered successfully:', response.data.user);
        setRegistrationStatus('success');
      } else {
        console.error("message");
        setRegistrationStatus('error');
      }

    } catch (error) {
      console.error('Error during registration:', error);
      setRegistrationStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    console.log('Cancel clicked!');
    setCurrentStep(1);
  };

  const projectId = 'ebbfecd20f47ecb2fac81f5979ba2d48';

  const metadata = {
    name: 'zemcos',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
  };

  const chains = [bsc];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
  createWeb3Modal({ wagmiConfig, projectId, chains });

  return (
    <ProfessionalBox>
      <Card>
        {currentStep === 1 && (
          <>
            <Title>Step 1: Connect Wallet</Title>
            <Description>Connect your wallet to get started.</Description>
            <ButtonContainer>
              <WagmiConfig config={wagmiConfig}>
                <w3m-button />
              </WagmiConfig>
              <Button onClick={handleConnectWallet}>Next</Button>
            </ButtonContainer>
          </>
        )}
        {currentStep === 2 && (
          <>
            <Title>Step 2: Refer Code</Title>
            <Description>Enter a referral code if you have one.</Description>
            <FormContainer>
              <FormLabel htmlFor="referCode">Refer Code:</FormLabel>
              <FormInput
                type="text"
                id="referCode"
                value={referCode}
                onChange={(e) => setReferCode(e.target.value)}
                placeholder="Enter refer code"
                required
              />
              <Button type="submit">Next</Button>
            </FormContainer>
          </>
        )}
        {currentStep === 3 && (
          <>
            <Title>Step 3: Simple Button</Title>
            <Description>Click the button to complete the process.</Description>
            <ButtonContainer>
              <Button onClick={handleSimpleButton} disabled={loading}>
                {loading ? 'Processing...' : 'Simple Button'}
              </Button>
              <Button onClick={handleCancel} style={{ backgroundColor: '#e74c3c' }}>
                Cancel
              </Button>
            </ButtonContainer>
            {registrationStatus === 'success' && <div>Done! Registration successful.</div>}
            {registrationStatus === 'error' && <div>Error! Registration failed.</div>}
          </>
        )}
      </Card>
    </ProfessionalBox>
  );
};

export default ExampleApiRequest;
